import React, { createContext, useState, useEffect } from 'react';
import { Platform } from 'react-native';
import * as RNIap from 'react-native-iap';

export const InAppParchaseContext = createContext<{ isInitialized: boolean }>({
  isInitialized: false,
});

export default function InAppParchaseProvider({ children }: { children: any }) {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (Platform.OS === 'web') {
      return;
    }
    const initializeIAP = async () => {
      try {
        if (!isInitialized) {
          await RNIap.initConnection();
          setIsInitialized(true);
        }
      } catch (error) {
        console.error('InAppPurchase Initialization Error', error);
      }
    };
    initializeIAP();
    return () => {
      RNIap.endConnection();
    };
  }, []);

  return (
    <InAppParchaseContext.Provider value={{ isInitialized }}>
      {children}
    </InAppParchaseContext.Provider>
  );
}
