import { setValueBindingScreen } from '@common/redux/slice/page';
import { IAppInfo, IPage } from '@common/types/element';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getScreenBinding } from '@common/services';
import { FindScreenBindingResult } from 'click-types';
import { isEmpty, findIndex, debounce } from 'lodash';
import useRefreshData from './useRefreshData';

type Props = {
  screen: IPage;
  dependencies: object;
  appInfo: IAppInfo | undefined;
};

const filterDatePicker = (metadata: Record<string, any>[]) =>
  metadata.filter(
    (child) =>
      child.type === 'date-picker' &&
      child?.defaultDateValue?.label === 'Current Time'
  );

const convertDateValue = (
  values: Record<string, any>,
  metadata: Record<string, any>[] = []
) => {
  return Object.keys(values).reduce((acc, id) => {
    const index = findIndex(filterDatePicker(metadata), { id: id });
    return {
      ...acc,
      ...(index === -1 && { [id]: values[id] }),
    };
  }, {});
};

function useBindingScreen({ screen, dependencies, appInfo }: Props) {
  const dispatch = useDispatch();
  const { databaseRefresh, actionRefresh } = useRefreshData({ obj: screen });

  const fetchBindingPage = useCallback(
    debounce(
      async (screen, dependencies, appInfo, controller) => {
        try {
          if (appInfo) {
            const screenBindingResult: FindScreenBindingResult =
              await getScreenBinding({
                appId: appInfo?.id,
                dependencies,
                screenUuid: screen.screenUuid,
                controller,
              });
            if (isEmpty(screenBindingResult?.data)) return;

            dispatch(
              setValueBindingScreen({
                data: convertDateValue(
                  screenBindingResult?.data,
                  screen.metadata
                ),
                screenUuid: screen.screenUuid,
              })
            );
          }
        } catch (error) {
          console.log('error', error);
        }
      },
      300,
      { leading: true }
    ),
    []
  );

  useEffect(() => {
    if (!appInfo) return;
    const controller = new AbortController();
    fetchBindingPage(screen, dependencies, appInfo, controller);

    return () => {
      controller.abort();
    };
  }, [
    fetchBindingPage,
    screen,
    dependencies,
    appInfo,
    databaseRefresh,
    actionRefresh,
  ]);
}

export default useBindingScreen;
