import { useMemo } from 'react';
import {
  currentRecordSelector,
  getCurrentRecord,
} from '@common/redux/selectors/database';
import { useSelector } from 'react-redux';
import { appInfoSelector, locationSelector } from '@common/redux/selectors/app';
import { profileSelector } from '@common/redux/selectors/auth';
import {
  getDefaultInputsWithIds,
  getFormInputsWithIds,
  getValueInputsWithIds,
} from '@common/redux/selectors/formInputs';
import { localeSelector } from '@common/redux/selectors/language';
import { isEmpty, isEqual } from 'lodash';
import { extractDependenciesFromObject } from '@common/utils/binding';
import { getListMultiSelectSelector } from '@common/redux/selectors/multiSelect';
import { showQrCodeSelector } from '@common/redux/selectors/page';

/**
 * Compute the dependencies of the object.
 * @param props.obj The data of the object computed. Make sure it doesn't change.
 * @param props.currentListIds The current list ids of the object.
 * */
const useDependencies = (props: {
  obj: any;
  currentListIds?: Record<string, any>;
}) => {
  const { obj = {}, currentListIds } = props;
  const appInfo = useSelector(appInfoSelector);
  const loggedUser = useSelector(profileSelector);
  const currentLocation = useSelector(locationSelector);
  const locale = useSelector(localeSelector);

  /** The ids of the input and the route param which the object depends on. */
  const { routeParams, inputIds } = useMemo(
    () => extractDependenciesFromObject(obj ?? {}),
    [obj]
  );

  /** Get the values of the current record current object is depending on.*/
  const currentRecord = useSelector(getCurrentRecord(routeParams), isEqual);

  /** Get the values of the input components current object is depending on.*/
  const valueInputs = useSelector(getValueInputsWithIds(inputIds), isEqual);

  /** Get the values of the form input components current object is depending on. */
  const valueFormInputs = useSelector(getFormInputsWithIds(inputIds), isEqual);

  /** Get the values of the input components (in case they have default values) current object is depending on. */
  const defaultValues = useSelector(getDefaultInputsWithIds(inputIds), isEqual);

  /** Get the list select option of components current object is depending on. */
  const listSelectOptions = useSelector(getListMultiSelectSelector(), isEqual);

  /** Get the value of the action QRCode Scanner */
  const { qrCode } = useSelector(showQrCodeSelector, isEqual);

  /** The dependencies of the object. */
  const dependencies = useMemo(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return {
      appInfo,
      timeZone,
      loggedUser: loggedUser?.id,
      locale,
      ...(!isEmpty(currentListIds) && {
        currentListIds,
      }),
      ...(!isEmpty(valueInputs) && { valueInputs }),
      ...(!isEmpty(valueFormInputs) && { valueFormInputs }),
      ...(!isEmpty(defaultValues) && { defaultValues }),
      ...(!isEmpty(currentRecord) && { routeParam: currentRecord }),
      ...(!isEmpty(currentLocation) && { currentLocation }),
      ...(!isEmpty(listSelectOptions) && { listSelectOptions }),
      ...(!isEmpty(qrCode) && { scannerId: qrCode }),
    };
  }, [
    appInfo,
    valueInputs,
    loggedUser?.id,
    locale,
    currentListIds,
    valueFormInputs,
    defaultValues,
    currentRecord,
    currentLocation,
    listSelectOptions,
    qrCode,
  ]);

  return { dependencies };
};

export default useDependencies;
