import {
  bindingComponentType,
  CURRENT_LOCATION_KEY,
  dataBindingType,
  ignoreKeyBinding,
  listItemKey,
  listObject,
  typeBindings,
} from '@common/constants';
import store from '@common/redux/store';
import { ElementType, IObj, IPage } from '@common/types';
import { IOnPressProps } from '@common/types/action';
import { every, forEach, get, isArray, isEmpty, omit, some } from 'lodash';
import { getSourceDataBinding, traverseObject } from './binding';
import { flattedSourceString } from './source';

export const includesBinding = (value: any[]) => {
  return some(value, (item) => typeBindings.includes(item?.type));
};

export const isStringArray = (value: any[]) => {
  return every(value, (item) => typeof item === 'string');
};

export const getValueInputDependencies = (
  inputs: string[],
  valueInputs: any
) => {
  return inputs.reduce((pre: any, curr: string) => {
    return {
      ...pre,
      ...(!isEmpty(valueInputs[curr]) ? { [curr]: valueInputs[curr] } : {}),
    };
  }, {});
};

/**
 * Checks type of the given component
 * @param component UI component metadata
 * @returns Component type
 */
export const checkingTypeComponent = (
  component: Record<string, any>
): string => {
  if (listObject.includes(component?.componentName))
    return bindingComponentType.MULTIPLE;

  if (component?.componentName === 'Map')
    return get(component, 'attributes.markerType', bindingComponentType.SIMPLE);

  return bindingComponentType.SIMPLE;
};

const getPathBinding = (obj: any, initPath: string = '') => {
  if (typeof obj === 'string') return obj;

  if (Array.isArray(obj) || obj?.type === 'binding' || isEmpty(obj)) {
    return initPath;
  }

  const keys = Object.keys(obj);
  let resp: any = '';

  forEach(keys, (k) => {
    if (!isEmpty(resp)) return resp;

    const value = obj[k];
    const newPath = !isEmpty(initPath) ? `${initPath}.${k}` : k;

    if (Array.isArray(value) && !isStringArray(value)) {
      resp = includesBinding(value) ? newPath : getPathBinding(value, newPath);
    } else if (
      !isEmpty(value) &&
      typeof value == 'object' &&
      !Array.isArray(value)
    ) {
      if (typeBindings.includes(value.type)) resp = newPath;
      else resp = getPathBinding(value, newPath);
    }
  });

  return resp;
};

export const ignoreKeySimpleObj = (obj: any) => {
  const newObj: any = {};
  const keys = Object.keys(obj);
  forEach(keys, (k) => {
    if (!ignoreKeyBinding.includes(k)) newObj[k] = obj[k];
  });
  return newObj;
};

const getPathNestedChild = (arrCom: Record<string, any> = [], k: string) => {
  const flattedChild = getPathBindingChildren(arrCom, k);
  return Object.keys(flattedChild).reduce((pre: any, curr: string) => {
    const key = curr
      .split('.')
      .filter(
        (item) => !['children', 'binding'].includes(item) && !/]$/.test(item)
      )
      .join('.');
    return { ...pre, [key]: flattedChild[curr] };
  }, []);
};

const getPathBindingChildren = (
  arrCom: Record<string, any> = [],
  k: string
) => {
  const children = arrCom.filter(
    (com: any) => checkingTypeComponent(com) === 'simple'
  );
  return children.reduce(
    (pre: Record<string, any>, child: Record<string, any>, idx: number) => {
      const value = getBindingPath(omit(child, 'children'), `${k}.[${idx}]`);

      return {
        ...pre,
        ...Object.keys(value).reduce((acc: any, curr) => {
          const key = curr
            .split('.')
            .filter((item) => !['attributes'].includes(item))
            .slice(2)
            .join('.');
          return { ...acc, [`${child.id}.${key}`]: value[curr] };
        }, {}),
        ...(child?.children &&
          getPathNestedChild(child?.children, `${k}.[${idx}].children`)),
      };
    },

    {}
  );
};

export const getBindingPath = (obj: any, initPath: string = '') => {
  let result: Record<string, any> = {};
  const keys = Object.keys(ignoreKeySimpleObj(obj)).filter(
    (key) =>
      obj[key] && typeof obj[key] === 'object' && !dataBindingType.includes(key)
  );
  forEach(keys, (k) => {
    const newPath = !isEmpty(initPath) ? `${initPath}.${k}` : k;
    const value = obj[k];

    if (listItemKey.includes(k)) {
      result = { ...result, [k]: getPathBindingChildren(value, k) };
    } else {
      if (isArray(value) && includesBinding(value)) result[newPath] = newPath;
      else if (!isArray(value)) {
        if (typeBindings.includes(value?.type)) result[newPath] = newPath;
        else result = { ...result, ...getBindingPath(value, newPath) };
      }
    }
  });
  return result;
};

const flattedChildComponent = (children: Record<string, any>[] = []) => {
  let result: any[] = [];

  children.forEach((child) => {
    if (['group'].includes(child?.type)) {
      return (result = [...result, ...flattedChildComponent(child?.children)]);
    } else if (!['section'].includes(child?.type)) {
      result.push(child);
    }
  });

  return result;
};

export function getValueByKey(
  obj: Record<string | number, any>,
  key: string
): any {
  for (const k in obj) {
    if (k == key) return obj[k];
    if (typeof obj[k] == 'object' && obj[k] !== null) {
      const result = getValueByKey(obj[k], key);
      if (result) return result;
    }
  }
  return;
}

export const getValueBindingComponent = (attrs: any, path: string) => {
  const { data = {}, record = {}, initId = '', id = '' } = attrs;
  const defaultValue = flattedSourceString(get(attrs, path));

  let value;
  if (!isEmpty(record)) {
    // get data in custom list
    const valueBinding = getValueByKey(record, attrs.id);
    value = getValueByKey(valueBinding, path);
  } else {
    value = getValueByKey(data, path);
  }
  return typeof value === 'boolean' ? value : value || defaultValue;
};

/**
 * Recursively extracts 'tableId' values from a nested object.
 * @param obj - The input object to traverse.
 * @returns An array of unique 'tableId' values.
 */
export const extractTableIdsFromObject = (obj: any): string[] => {
  const uniqueTableIds: Set<string> = new Set();

  traverseObject(obj, (child) => {
    if (typeof child?.tableId === 'string') uniqueTableIds.add(child.tableId);
  });
  return Array.from(uniqueTableIds);
};

export const memoriesConditional = (query: string[]) => (pre: any, next: any) =>
  every(
    query,
    (key: string) =>
      JSON.stringify(get(pre, key)) === JSON.stringify(get(next, key))
  );

const checkCurrentLocation = (obj: any): any => {
  if (isEmpty(obj) || typeof obj === 'string') return false;
  const keys = Object.keys(omit(obj, [CURRENT_LOCATION_KEY]));

  return some(keys, (key) => {
    const currentValue = obj[key];
    if (key === 'type' && currentValue === CURRENT_LOCATION_KEY) return true;

    if (!isEmpty(currentValue) && isArray(currentValue)) {
      return some(currentValue, (item) => checkCurrentLocation(item));
    } else if (
      !isEmpty(currentValue) &&
      typeof currentValue === 'object' &&
      !isArray(currentValue)
    ) {
      return checkCurrentLocation(currentValue);
    }
  });
};

const checkMapPage = (page: IPage) => {
  return some(page.metadata, (component: Record<any, any>) => {
    switch (component.componentName) {
      case 'Map':
        const markerType = get(component, 'attributes.markerType');

        const showCurrentLocation = get(
          component,
          `attributes.${
            markerType === bindingComponentType.SIMPLE ? 'marker' : 'markers'
          }.${CURRENT_LOCATION_KEY}`
        );

        if (showCurrentLocation) return showCurrentLocation;
        return checkCurrentLocation(component);
      default:
        return checkCurrentLocation(component);
    }
  });
};

export const appUseMap = (pages: IPage[]): any => {
  return some(pages, (page: IPage) => checkMapPage(page));
};

export const convertImage = (attributes: any) => {
  const { defaultValue = null } = attributes;
  if (!defaultValue) return;
  const { imageType, imageUploaded = null, imageUrl = null } = defaultValue;

  const isItemList = !!attributes?.record;
  const dataBinding = isItemList ? attributes?.record : attributes?.data;
  const id = isItemList ? attributes.initId : attributes?.id;

  switch (imageType) {
    case 'uploaded':
      return imageUploaded;

    case 'url':
      return get(
        dataBinding,
        `${id}.defaultValue.imageUrl`,
        flattedSourceString(imageUrl)
      );

    default:
      const imageBinding =
        get(dataBinding, `${id}.defaultValue`) ||
        get(dataBinding, `defaultValue`);
      return imageBinding?.url;
  }
};

export const convertDate = (valueBinding: any, props: IObj) => {
  if (
    ['currentTime', 'startOfToday'].includes(valueBinding) ||
    !valueBinding ||
    JSON.stringify(props?.defaultDateValue || {}).includes('Current Time')
  )
    return null;

  return valueBinding;
};

export const getListObjectMetadata = (metadata: IObj) => {
  return metadata.filter((obj: IObj) => {
    const componentType = checkingTypeComponent(obj);
    const dataBinding = getSourceDataBinding(obj);
    const tableSelected = get(dataBinding, 'source.tableId');
    return componentType === 'multiple' && tableSelected;
  });
};

export const getSimpleObject = (metadata: IObj[]) => {
  return metadata.reduce((pre: string[], obj) => {
    return [
      ...pre,
      ...(checkingTypeComponent(obj) === 'simple' ? [obj.id] : []),
    ];
  }, []);
};

export const getComponentAction = (attrs: IObj) => {
  if (isEmpty(attrs?.actions)) return null;
  const actions = Object.keys(attrs?.actions || {});

  return actions[0];
};

export const flattedComponent = (metadata: any[] = []): any[] => {
  if (metadata.length <= 0) return [];
  return metadata.reduce((pre: any[], curr: IObj) => {
    if (
      curr?.children &&
      Array.isArray(curr?.children) &&
      curr?.children.length > 0
    ) {
      return [
        ...pre,
        omit(curr, ['children']),
        ...flattedComponent(curr?.children),
      ];
    }

    return [...pre, curr];
  }, []);
};

export const getFlattedMetadata = (screenUuid: string) => {
  if (!screenUuid) return [];

  const state = store.getState();

  const screen = state.pageInfo.pages[screenUuid];

  return flattedComponent(screen?.metadata);
};

export const convertParamAction = ({
  obj,
  dependencies,
  options,
  objectId,
  actionId,
  messages,
  arrayAction,
}: {
  obj: ElementType;
  dependencies: Record<string, any>;
  options: IOnPressProps;
  objectId: string;
  actionId: string;
  messages: Record<string, any>;
  arrayAction: Record<string, any>[];
}) => {
  const {
    itemListClick = null,
    idUnivaPaySubscription = null,
    record = {},
    formId = null,
    code = '',
    ids = [],
    scannerCode = null,
    inAppPurchase = null,
  } = options || {};

  const clientValues = {
    ...dependencies,
    valueInputs: {
      ...dependencies.valueInputs,
      ...(scannerCode && { [obj.id]: scannerCode }),
    },
    currentListIds: {
      ...dependencies?.currentListIds,
      ...((itemListClick && omit(itemListClick, ['recordObj'])) || {}),
    },
    ...(idUnivaPaySubscription && {
      idUnivaPaySubscription: idUnivaPaySubscription,
    }),
    ...(inAppPurchase && {
      inAppPurchase,
    }),
    sortedItemNumber: itemListClick?.sortedItemNumber,
  };

  const currentRecordIds = {
    ...obj.currentRecordIds,
    ...((itemListClick && itemListClick?.recordObj) || {}),
  };

  return {
    objectId,
    screenUuid: obj?.screenUuid,
    actionId,
    clientValues,
    currentRecordIds,
    messages,
    arrayAction,
    data: {
      ...record,
      ...(code && { code }),
      ...(ids && ids.length > 0 && { ids }),
      ...(inAppPurchase && {
        inAppPurchase,
      }),
    },
    ...(formId && {
      formId: formId,
    }),
  };
};
