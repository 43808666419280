export default {
  name: 'Video',
  displayName: {
    en: 'Video',
    ja: 'Video',
  },
  resizeX: true,
  resizeY: true,
  props: [
    {
      name: 'videoSource',
      displayName: { ja: 'Video source', en: 'Video source' },
      type: 'text', // TODO: create 'video' type
    },
    {
      name: 'showVideoControl',
      displayName: {
        en: 'Show video control',
        ja: 'Show video control',
      },
      type: 'boolean',
      default: true,
    },
    {
      name: 'autoPlay',
      displayName: {
        en: 'Auto play',
        ja: 'Auto play',
      },
      type: 'boolean',
      default: true,
    },
    {
      name: 'muted',
      displayName: {
        en: 'Mute',
        ja: 'Mute',
      },
      type: 'boolean',
      default: false,
    },
    {
      name: 'loop',
      displayName: {
        en: 'Loop',
        ja: 'Loop',
      },
      type: 'boolean',
      default: true,
    },
    {
      name: 'fullscreen',
      displayName: {
        en: 'Full screen',
        ja: 'Full screen',
      },
      type: 'boolean',
      default: false,
    },
    {
      name: 'backgroundColor',
      displayName: {
        en: 'Background Color',
        ja: '背景色',
      },
      type: 'color',
      default: '#ffffff',
    },
    {
      name: 'action',
      type: 'action',
      displayName: {
        en: 'ClickFlow when Video played',
        ja: 'ClickFlow when Video played',
      },
    },
    {
      name: 'successActions',
      type: 'action',
      displayName: {
        en: 'ClickFlow when Video finished',
        ja: 'ClickFlow when Video finished',
      },
    },
  ],
  childComponents: [
    {
      name: 'preview',
      displayName: {
        en: 'Preview Image',
        ja: 'Preview Image',
      },
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: 'false',
        },
        {
          name: 'image',
          displayName: { ja: 'Preview Image', en: 'Preview Image' },
          type: 'text',
        },
      ],
    },
  ],
};
