import {
  ActionStatus,
  TABLE_DELETE,
  TOKEN_KEY,
} from '@common/constants/shared';
import { setError } from '@common/redux/slice/app';
import {
  setCurrentRecordIds,
  setActionRefresh,
} from '@common/redux/slice/database';
import { resetValue, resetValueForm } from '@common/redux/slice/formInputs';
import { setListSelectOptions } from '@common/redux/slice/multiSelect';
import store from '@common/redux/store';
import { fetchScreenAction } from '@common/services';
import { ActionTypes, IAction, IObj } from '@common/types';
import { MetadataAction } from '@common/types/action';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { find, get, isEmpty, some } from 'lodash';
import * as actions from './ActionItem';
import { getInputFromField } from './func/helps';
import { batch } from 'react-redux';

export type ActionResponse = {
  status: string;
  message?: string;
  accessToken?: string;
  currentRecordIds?: IObj;
  recordUpdated?: object;
};

const notApiActions = [
  ActionTypes.LOGOUT,
  ActionTypes.NAVIGATE,
  ActionTypes.FORGOT_PASSWORD,
];

type ActionProps = {
  objectId?: string;
  actionId: string;
  clientValues: IObj;
  screenUuid: string;
  currentRecordIds?: IObj;
  messages: IObj;
  data?: IObj;
  screenAction?: boolean;
  arrayAction: any[];
  formId?: string;
  scannerId?: string;
};

type OnPressActionProps = {
  currentRecordIds?: IObj;
  messages?: IObj;
  data?: IObj;
  arrayAction?: any[];
  formId?: string;
};

const isOAuthAction = (actionType: ActionTypes) => {
  return [
    ActionTypes.LINE_LOGIN,
    ActionTypes.DOCOMO_LOGIN,
    ActionTypes.DID_LOGIN,
    ActionTypes.TRUSTDOCK_LOGIN,
    ActionTypes.APPLE_LOGIN,
    ActionTypes.SIGNUP,
    ActionTypes.SIGNIN,
  ].includes(actionType);
};

const allowAPI = (actions: IAction[]) =>
  some(actions, (action) => {
    const conditionals = action.options?.conditionals || [];
    return (
      conditionals.length > 0 || !notApiActions.includes(action.actionType)
    );
  });

export const actionPromise = async (props: ActionProps) => {
  const {
    objectId,
    actionId,
    clientValues,
    screenUuid,
    data,
    screenAction,
    arrayAction,
    scannerId,
  } = props;
  try {
    const allowApi = allowAPI(arrayAction);
    let response;

    if (allowApi || actionId === TABLE_DELETE) {
      response = await fetchScreenAction({
        objectId,
        actionId,
        clientValues: { ...clientValues, scannerId },
        screenUuid,
        data,
        screenAction,
      });
    } else {
      response = arrayAction;
    }
    return onPressAction(response, props);
  } catch (error) {
    console.log('actionPromise', error);
  }
};

export const onPressAction = async (
  response: any[],
  props?: OnPressActionProps
) => {
  try {
    const {
      currentRecordIds = {},
      messages = {},
      data,
      arrayAction,
      formId,
    } = props ?? {};
    const dispatch = store.dispatch;
    const result: any[] = [];
    const refreshData: string[] = [];
    let inputResetValue = {};
    let formResetValue = {};

    const hasRouteParamLink = response.find(
      (action) =>
        action.actionType === ActionTypes.NAVIGATE &&
        action?.visibility !== false &&
        action?.options?.target !== actions.NAVIGATION_BACK
    );
    for (let i = 0; i < response.length; i++) {
      const currentAction = response[i];
      const isActionAvailable = JSON.stringify(
        get(currentAction, 'visibility', true)
      );

      if (isActionAvailable === 'false') continue;

      const isAuthAction = isOAuthAction(currentAction.actionType);
      const actionResult: ActionResponse = await runAction(currentAction, {
        currentRecordIds,
        messages,
        record: data,
        ...(arrayAction &&
          arrayAction.length > 0 && { actionItem: arrayAction[i] }),
      });

      if (actionResult.status === ActionStatus.SUCCESS) {
        const { accessToken = null, currentRecordIds = null } = actionResult;

        if (isAuthAction && accessToken) {
          AsyncStorage.setItem(TOKEN_KEY, accessToken);
        }

        if (currentRecordIds && hasRouteParamLink) {
          dispatch(setCurrentRecordIds(currentRecordIds));
        }

        dispatch(setListSelectOptions(data?.ids || []));

        const metadataAction = find(arrayAction, { id: currentAction.id });

        const { inputs, formControl } =
          getInputFromField(metadataAction, { record: data, formId }) || {};

        if (
          [
            ActionTypes.CREATE_OBJECT,
            ActionTypes.SIGNUP,
            ActionTypes.UPDATE_OBJECT,
          ].includes(currentAction?.actionType)
        ) {
          inputResetValue = { ...inputResetValue, ...inputs };
          formResetValue = { ...formResetValue, ...formControl };
        }
        if (
          [
            ActionTypes.CREATE_OBJECT,
            ActionTypes.DELETE_OBJECT,
            ActionTypes.UPDATE_OBJECT,
            ActionTypes.MULTI_SELECT_UPDATE,
            ActionTypes.MULTI_SELECT_DELETE,
          ].includes(currentAction?.actionType)
        ) {
          refreshData.push(currentAction.response?.databaseId);
        }
      } else {
        if (isAuthAction) {
          dispatch(setError(actionResult));

          return actionResult;
        }
      }

      result.push({ ...actionResult, actionId: currentAction.id });
    }

    batch(() => {
      if (!isEmpty(inputResetValue)) {
        dispatch(resetValue(inputResetValue));
      }
      if (!isEmpty(formResetValue)) {
        dispatch(resetValueForm(formResetValue));
      }
      if (!hasRouteParamLink && refreshData.length) {
        dispatch(setActionRefresh(refreshData));
      }
    });

    return result;
  } catch (error) {
    console.log('actionPromise', error);
  }
};

function runAction(action: IAction, metadata: MetadataAction) {
  const { actionType } = action;

  switch (actionType) {
    // signin user action___________
    case ActionTypes.SIGNIN:
    case ActionTypes.LINE_LOGIN:
      return actions.signIn(action, metadata);

    // //signup user action___________
    case ActionTypes.SIGNUP:
      return actions.signUp(action, metadata);

    // //Logout user
    case ActionTypes.LOGOUT:
      return actions.logOut(action);

    //forgot password
    case ActionTypes.FORGOT_PASSWORD:
      return actions.forgotPassword(action, metadata);

    //create user
    case ActionTypes.CREATE_OBJECT:
      return actions.createObject(action, metadata);

    //update user
    case ActionTypes.UPDATE_OBJECT:
      return actions.updateObject(action, metadata);

    // //delete user
    case ActionTypes.DELETE_OBJECT:
      return actions.deleteObject(action, metadata);

    //set Input value
    case ActionTypes.SET_INPUT_VALUE:
      return actions.setInputValue(action, metadata);

    // send email
    case ActionTypes.SEND_EMAIL:
      return actions.sendEmail(action, metadata);

    //push notification
    case ActionTypes.PUSH_NOTIFICATION:
      return actions.pushNotification(action, metadata);

    //external link
    case ActionTypes.EXTERNAL_LINK:
      return actions.externalLink(action, metadata);

    // //customAction
    case ActionTypes.CUSTOM:
      return actions.customAction(action, metadata);

    case ActionTypes.TRACKING_ANALYSTIC:
      return actions.sendEventAnalytics(action);

    case ActionTypes.QR_CODE_SCANNER:
      return action;

    // navigate action________________
    default:
      return actions.executeNavigate(action, metadata);
  }
}
