import { simpleBindingSelector } from '@common/redux/selectors/page';
import { IOnPressProps } from '@common/types/action';
import React, { FC } from 'react';
import { get, isEmpty, pick } from 'lodash';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

type Props = {
  ObjectClass: FC;
  obj: any;
  dependencies: any;
  onPress: (actionId: string, options: IOnPressProps) => any;
};

const getVisibility = (props: { obj: any; data?: any }) => {
  const { obj, data } = props;
  if (isEmpty(obj?.visibilities)) return true;
  if (obj?.record) return get(obj?.record, `${obj.id}.visibility`, false);
  return get(data, 'visibility', false);
};

const SimpleObjectWrap = ({
  ObjectClass,
  obj,
  dependencies,
  onPress,
}: Props) => {
  const data = useSelector(simpleBindingSelector(obj.id));

  const visibility = getVisibility({ obj, data });

  //visibility
  if (JSON.stringify(visibility) === 'false')
    return (
      <View
        style={{
          ...pick(obj, ['width', 'height', 'marginTop', 'marginLeft']),
          zIndex: -1,
        }}
      />
    );

  return (
    <ObjectClass
      {...obj}
      data={data}
      dependencies={dependencies}
      onPress={onPress}
    />
  );
};

export default SimpleObjectWrap;
