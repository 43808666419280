import { useMemo, useState, useCallback, useEffect } from 'react';
import { get } from 'lodash';
import { getValueBinding } from '../shared';
import { CardElement } from '@stripe/react-stripe-js';
import { Platform } from 'react-native';
import { STATUS_STRIPE } from './constant';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';

export const KEY_MODE_STRIPE = {
  TEST: 'test',
  LIVE: 'live',
};

const initialState = {
  text: { status: null, message: null },
};

export type ReceivedProps = {
  attributes: Record<string, any>;
  id: string;
  data: any;
  dependencies: Record<string, any>;
  stripePayment: (data: any) => Promise<any>;
  locale: 'en' | 'ja';
  paramsPayload: { appId: string };
  screenUuid: string;
  onPressAction: (actions: any[]) => any;
  test_mode?: {
    enabledTestMode: boolean;
    publishableKey: string;
    secretKey: string;
  };
};

const useStripeSubscription = (props: ReceivedProps) => {
  const {
    id,
    data,
    attributes,
    dependencies,
    stripePayment,
    screenUuid,
    paramsPayload,
    onPressAction,
    locale,
    test_mode,
  } = props;

  const {
    title: { enabled, text },
  } = attributes;

  const bindingValue = useMemo(() => getValueBinding(id, data, props), [props]);

  const publicKeyDefault = get(props, 'publicKey');
  const accountConnectId = get(dependencies, 'appInfo.accountConnectId');
  const emailBuyer = get(bindingValue, 'email.emailBuyer');
  const submitButtonText = get(bindingValue, 'submitButton.text', 'Pay Now');

  const { enabledTestMode, publishableKey } = test_mode || {};

  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState<{
    text: {
      status:
        | 'succeeded'
        | 'active'
        | 'requires_payment_method'
        | 'notValidOrRequired'
        | null;
      message: string | null;
    };
  }>(initialState);

  const handleSubmit = async (
    stripe?: Stripe | null,
    elements?: StripeElements | null
  ) => {
    try {
      setLoading(true);
      if (Platform.OS === 'web') {
        if (!stripe || !elements) return;

        const paymentMethodRes = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement) || { token: '' },
          billing_details: {
            name: emailBuyer,
          },
        });

        const { paymentMethod, error } = paymentMethodRes || {};

        if (!paymentMethod?.id || error) {
          setCard((prev) => ({
            ...prev,
            text: {
              status: 'requires_payment_method',
              message: error?.message || 'Payment failed',
            },
          }));
          return;
        }

        const { appId } = paramsPayload || {};

        const payload = {
          paymentMethodId: paymentMethod.id,
          objectId: id,
          appId: appId,
          screenUuid: screenUuid,
          dependencies,
        };

        const response = await stripePayment(payload);

        if (response.status === STATUS_STRIPE.ACTIVE) {
          setCard((prev) => ({
            ...prev,
            text: { status: 'active', message: 'Payment successful' },
          }));
        } else {
          setCard((prev) => ({
            ...prev,
            text: {
              status: 'requires_payment_method',
              message: 'Payment failed',
            },
          }));
        }
        handleAction(response?.actions);
      }
    } catch (error: any) {
      // console.log('error', error.response);
      setCard((prev) => ({
        ...prev,
        text: {
          status: 'requires_payment_method',
          message: error?.response?.data?.message || 'Payment failed',
        },
      }));
      handleAction(error?.response?.data?.data?.actions);
    } finally {
      setLoading(false);
    }
  };

  const handleAction = (actions: any[] = []) => {
    if (!onPressAction || !actions.length) return;

    onPressAction(actions);
  };

  const updateStatusCard = (
    status:
      | 'succeeded'
      | 'active'
      | 'requires_payment_method'
      | 'notValidOrRequired'
      | null,
    message: string | null
  ) => setCard({ text: { status, message } });

  return {
    ...props,
    loading,
    submitButtonText,
    enabledTitle: enabled,
    titleText: text,
    paramsPayload,
    accountConnectId,
    publicKeyDefault,
    enabledTestMode,
    publishableKey,
    card,
    emailBuyer,
    handleAction,
    handleSubmit,
    updateStatusCard,
  };
};

export type Props = ReturnType<typeof useStripeSubscription>;

export default useStripeSubscription;
