import ObjectRender from '@common/screens/RenderScreen/ObjectRender';
import { IRectangle } from '@common/types/element';
import {
  getActions,
  getItemListClick,
} from '@common/utils/handleActions/func/helps';
import { isEmpty } from 'lodash';
import React, { FC, useRef } from 'react';
import { GestureResponderEvent, TouchableOpacity, View } from 'react-native';
import createStyles from './style';

const Rectangle: FC<IRectangle> = (attrs) => {
  const touchRef = useRef(null);

  const styles = createStyles(attrs);

  const hasAction = !isEmpty(attrs.actions);

  const layout = {
    offsetTop: attrs?.y,
    offsetLeft: attrs?.x,
    parentWidth: attrs?.width,
  };

  if (hasAction) {
    const handlePress = (e: GestureResponderEvent) => {
      let arrayAction = getActions(attrs.actions);
      const options = {
        itemListClick: getItemListClick(attrs?.record),
      };

      attrs.onPress && attrs.onPress(arrayAction, options);
    };

    return (
      <TouchableOpacity
        style={styles.container}
        activeOpacity={1}
        ref={touchRef}
        onPress={handlePress}
      >
        {attrs?.children && (
          <ObjectRender
            arrComp={attrs?.children}
            isScreen={false}
            layout={layout}
          />
        )}
      </TouchableOpacity>
    );
  }

  return (
    <View style={styles.container}>
      <ObjectRender
        keyItem={attrs?.selectedItem?.itemIndex}
        arrComp={attrs?.children || []}
        isScreen={false}
        layout={layout}
      />
    </View>
  );
};

export default Rectangle;
